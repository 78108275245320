import React from "react";
import Error404 from "../../components/common/Error404";

/**
 * Returns 404 Not Found Page if user browse to any invalid route.
 *
 * @returns {React.ReactElement} jsx for the not found page.
 */
const NotFound = () => {
  return <Error404 />;
};

export default NotFound;
