import HTMLReactParser from "html-react-parser";
import Head from "next/head";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import getConfig from "next/config";
import { fetchContentFromCMSOnClient } from "../../src/services/api/common/common-api";
import { FnpPageType, getPageNameForCleverTap, setFnpPageType } from "../../src/utils/common";
import useCommonOmnitureSetup from "../hooks/analytics/omniture/useCommonOmnitureSetup";
import styles from "./styles/errorPage.module.scss";
import { cleverTapCommonWords, cleverTapEventsConstants } from "../../src/analytics/clevertapConstants";
import useClevertapSetup from "../hooks/analytics/clevertap/useClevertapSetup";
import callClevertapEvent from "../../src/analytics/clevertapUtility";

const { publicRuntimeConfig } = getConfig();
/**
 *  Returns 404 Page component if user browse to any invalid route.
 *
 * @returns {React.ReactElement} - React component for 404 routes not found.
 */
const Error404 = () => {
  const router = useRouter();
  const [errorData, setErrorData] = useState("");
  const appConfigs = useSelector((state) => state.appConfigs);
  const { isMobile } = appConfigs;
  useClevertapSetup();

  useEffect(() => {
    setFnpPageType(FnpPageType.ERROR_404);
  }, []);

  useEffect(() => {
    /**
     * To fetch values of error page content to be displayed on page.
     *
     */
    const fetchErrorData = async () => {
      try {
        const response = await fetchContentFromCMSOnClient(
          `global/static/web/${isMobile ? "mobile" : "desktop"}/popular-link-404-${isMobile ? "mobile" : "desktop"}`,
        );
        setErrorData(response);
      } catch (error) {
        setErrorData("");
        // eslint-disable-next-line no-console
        console.log(error.message);
      }
    };
    fetchErrorData();
  }, [isMobile]);

  useEffect(() => {
    const host = publicRuntimeConfig.D_HOST;
    const pageViewedData = {
      complete_url: `${host}${router.asPath}`,
      clean_url: `${host}${router.asPath}`.split("?")[0],
    };

    const errorValidationData = {
      page_name: router.asPath,
      page_type: getPageNameForCleverTap(),
      error_code: "404",
      validation_for: cleverTapCommonWords.PAGE_NOT_FOUND,
      validation_text: cleverTapCommonWords.PAGE_NOT_FOUND,
    };
    callClevertapEvent(cleverTapEventsConstants.pageViewed, pageViewedData);
    callClevertapEvent(cleverTapEventsConstants.validationViewed, errorValidationData);
  }, [router]);

  useCommonOmnitureSetup();

  if (isMobile) {
    return (
      <>
        <Head>
          <title>Oops! Page Not Found</title>
        </Head>
        <div className={styles["pagenotfound-container"]} data-test="404-component">
          <div className={styles["mobile-container-area"]}>
            <div className={styles["empty-msg"]}>
              <p className={styles.title}>
                Oops!
                <span>Page Not Found</span>
              </p>
              <p className={styles.info}>
                We can&#39;t find the page you are looking for. Why not try these popular pages.
              </p>
              {errorData && <div className={styles["all-buttons"]}>{HTMLReactParser(String(errorData))}</div>}
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Head>
        <title>Oops! Page Not Found</title>
      </Head>
      <div className={styles["pagenotfound-desktopContainer"]} data-test="404-component">
        <div className={styles["desktop-contentArea"]}>
          <div className={styles["desktop-contentAreaIn"]}>
            <div className={styles["desktop-left-sec"]}>
              <div className={styles["empty-msg"]}>
                <p className={styles.title}>
                  Oops!
                  <span>Page Not Found</span>
                </p>
                <p className={styles.info}>
                  We can&#39;t find the page you are looking for. Why not try these popular pages.
                </p>
              </div>
              {errorData && <div className={styles["desktop-popular-link"]}>{HTMLReactParser(String(errorData))}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
