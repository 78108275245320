import { useMemo } from "react";
import useCommonOmnitureData from "./useCommonOmnitureData";
import useOmnitureSetup from "./useOmnitureSetup";

/**
 * Home page omniture setup
 */
export default function useCommonOmnitureSetup() {
  const { rootGeoId, catalogId, checkLoginInfo } = useCommonOmnitureData();

  const omniData = useMemo(
    () => ({
      userDetails: {
        checkLoginInfo,
      },
      appConfigs: {
        rootGeoId,
        catalogId,
      },
    }),
    [catalogId, checkLoginInfo, rootGeoId],
  );

  useOmnitureSetup(omniData);
}
